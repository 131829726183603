import React, { useState } from 'react'
import { Link } from 'react-router-dom';
const Header = () => {
   const  [activemenu , setactivemenu] = useState(false);
   const  [isHeaderopen , setHeaderopen] = useState(false);
    const toggleClass = () => {
       setactivemenu(!activemenu);
       setHeaderopen(!isHeaderopen);
  }
  return (
    <>
        <div className={`menu_btn ${activemenu ? 'active' : ''}`} id="toggle" onClick={toggleClass}>
            <div className="button_container clr_change">
              <div className="menu_tx"> MENU</div>
              <span className="top clr_change"></span>
              <span className="middle clr_change"></span>
              <span className="bottom clr_change"></span>
            </div>
         </div>
    
       <div className={`overlay menu_new ${isHeaderopen ? 'open' : '' }`} id="overlay">
       <div> 
          <li className="menuitem"> <a href="/">Home </a></li>
          <li className="menuitem">
              <div className="School">About Us <i className="fas fa-angle-right"></i></div>
              <div className="submenu">
                  <li className="menuitem"> <Link to="/GeneralInfo"> General Info </Link></li>
                  <li className="menuitem"> <Link to="/OurMotto"> Our Motto </Link></li>
                  <li className="menuitem"> <Link to="/OurEmblem"> Our Emblem </Link></li>
                  <li className="menuitem"> <Link to="/OurPatron"> Our Patron </Link></li>
                  <li className="menuitem"> <Link to="/PrincipalDesk"> From Principal's Desk </Link></li>
                  <li className="menuitem"> <Link to="/MissionVision"> Mission & Vision </Link></li>
                  <li className="menuitem"> <Link to="/OurAchievements"> Our Achievements </Link></li>
                  <li className="menuitem"> <Link to="/AdvisoryMembers"> Advisory Board Members </Link></li>
                  <li className="menuitem"> <Link to="/OurFaculty"> Our Faculty </Link></li>
                  <li className="menuitem"> <Link to="/StudentStrength"> Student Strength </Link></li>
                  {/* <li className="menuitem"><Link to="pdf/Annual-Report.pdf" target="_blank"> Annual Report </Link></li> */}
              </div>
            </li>
            <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/MANDATORY-PUBLIC-DISCLOSURE.pdf" className="nav__link" target="_blank" >CBSE Disclosure</Link></li>
          <li className="menuitem">
            <div className="Academics">Academics <i className="fas fa-angle-right"></i></div>
            <div className="submenu">
              <li className="menuitem"> <Link to="/Curriculum"> Curriculum</Link></li>
              <li className="menuitem"> <Link to="/TeachingMethodology"> Teaching Methodology</Link></li>
              <li className="menuitem"> <Link to="/ITELearning"> IT & E-learning</Link></li>
              <li className="menuitem"> <Link to="/ExperientialLearning"> Experiential Learning</Link></li>
              <li className="menuitem"> <Link to="/SubjectShowcases"> Subject Showcases</Link></li>
              <li className="menuitem"> <Link to="/AssessmentEvaluation"> Assessment & Evaluation</Link></li>
              <li className="menuitem"> <Link to="/Tc">Transfer Certificate</Link></li>
              <li className="menuitem"> <Link to="pdf/Annual-Calendar-Grade-Pre-Nur-X.pdf" target="_blank">Annual Calendar</Link></li>
              <li className="menuitem"> <Link to="/studentExchangeProgram">Student Exchange Program</Link></li>
              <li className="menuitem"> <Link to="/result">Result</Link></li>
              {/* <li className="menuitem"> <Link to="/topperX">Topper X</Link></li>
              <li className="menuitem"> <Link to="/topperXII">Topper XII</Link></li> */}
              </div>
            </li>
          <li className="menuitem">
            <div className="Messages">Co-Curricular  <i className="fas fa-angle-right"></i></div>
            <div className="submenu"> 
              <li className="menuitem"> <Link to="/Sports">Sports</Link></li>
              <li className="menuitem"> <Link to="/ArtCraft">Art & Craft</Link></li>
              <li className="menuitem"> <Link to="/Music">Music</Link></li>
              <li className="menuitem"> <Link to="/Dance">Dance</Link></li>
              <li className="menuitem"> <Link to="/HobbyClubs">Hobby Clubs</Link></li>
              <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/List-of-Special-Assemblies.pdf" target="_blank">Assemblies</Link></li>
              <li className="menuitem"> <Link to="/TrainingTrainers">Training The Trainers</Link></li>
              <li className="menuitem"> <Link to="/CommunityServiceSocialWork">Community Service and Social Work</Link></li>
              </div> 
          </li>
          <li className="menuitem"> 
            <div className="Achievements">Admission  <i className="fas fa-angle-right"></i></div>
              <div className="submenu">
                  <li className="menuitem"> <Link to="/AdmissionProcess">Admission Process</Link></li>
                  <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/admission.pdf" target="_blank">Admission Form</Link></li>
                  <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/Registration-Form.pdf" target="_blank">Registration Form</Link></li>
                  <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/LVS-Prospectus.pdf" target="_blank">Prospectus</Link></li>
                  <li className="menuitem"> <Link to="/FeeStructure">Fee Structure</Link></li>
              </div>
            </li>
          <li className="menuitem"> 
            <div className="Office">CBSE Documents   <i className="fas fa-angle-right"></i> </div>
              <div className="submenu"> 
              <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/Book-list.pdf" target="_blank">Booklist</Link></li>
              <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/declaration.pdf" target="_blank">Declaration </Link></li>
              <li className="menuitem"> <Link to="/CommitteeDetails">Committee Details</Link></li>
              <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/Annual-Calendar-Grade-Pre-Nur-X.pdf" target="_blank">Annual Calendar</Link></li>
              <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/SMC.pdf" target="_blank">School Management Committee</Link></li>
              <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/Self-Affidavit.pdf" target="_blank">Self Affidavit</Link></li>
              <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/pta.pdf" target="_blank">Parent Teacher Association</Link></li>
              </div>
            </li>
          <li className="menuitem"> <Link to="/Circulars">Circulars </Link></li>
          <li className="menuitem"> <Link to="/Infrastructure">Infrastructure </Link></li>
          <li className="menuitem"> 
            <div className="Office">Gallery  <i className="fas fa-angle-right"></i></div>
            <div className="submenu">
              <li className="menuitem"> <Link to="/PhotoGallery">Photo Gallery </Link></li>
              <li className="menuitem"> <Link to="/Media">Media </Link></li>
            </div>
            </li>
          <li className="menuitem"> 
              <div className="Office">Careers  <i className="fas fa-angle-right"></i></div>
              <div className="submenu">
                <li className="menuitem"> <Link to="/JobOpportunities">Job Opportunities</Link></li>
                <li className="menuitem"> <Link to="https://webapi.entab.info/api/image/LVISGN/public/pdf/application-form.pdf">Application Form</Link></li>
              </div>
            </li>
          <li className="menuitem"><Link to="/Contact">Contact </Link></li>
    </div>
       </div>
     
    </>
  )
}

export default Header
